
<ng-template #shareUsers let-modal>
  <div class="modal-body">
    <div class="d-flex">
      <button type="button" class="close close-button cls" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true"><svg width="26" height="26" viewBox="0 0 26 26" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L12.9042 12.9042L23.8084 2" stroke="#95B9CB" stroke-width="3" stroke-linecap="round" />
            <path d="M23.8086 23.9043L12.9044 13.0001L2.00014 23.9043" stroke="#95B9CB" stroke-width="3"
              stroke-linecap="round" />
          </svg>
        </span>
      </button>
    </div>
    
    <div class="mt-2 pb-2" *ngIf="this.sharedone===true">
      <div class="row">
        <div class="col text-center">
          <svg width="78" height="79" viewBox="0 0 78 79" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="39" cy="39.4434" r="35" fill="url(#paint0_linear_5133_106802)"/>
            <circle cx="39" cy="39.4434" r="37" stroke="#0033A0" stroke-opacity="0.1" stroke-width="4"/>
            <path d="M35.1279 48.0558C35.5972 48.5251 36.3951 48.5251 36.8644 48.0558L50.6629 34.2573C51.1322 33.7879 51.1322 32.9901 50.6629 32.5207L48.9733 30.8311C48.5039 30.3618 47.753 30.3618 47.2837 30.8311L36.0196 42.0952L30.7161 36.8386C30.2468 36.3693 29.4958 36.3693 29.0265 36.8386L27.3369 38.5282C26.8675 38.9976 26.8675 39.7955 27.3369 40.2648L35.1279 48.0558Z" fill="white"/>
            <defs>
            <linearGradient id="paint0_linear_5133_106802" x1="4" y1="4.44336" x2="86.2417" y2="44.8845" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F2CD00"/>
            <stop offset="1" stop-color="#658D1B"/>
            </linearGradient>
            </defs>
            </svg>
        </div> 
      </div>
      <div class="row">
        <div class="col text-center pt-2">
          <h4 >
            You have successfully shared this report
          </h4>
          <p class="pt-3">
            <button class="btn btn-primary" (click)="modal.dismiss('Cross click')" style="min-width: 10rem;">Got it</button>
          </p>
        </div>
    
      </div>
    </div>

    <div *ngIf="this.sharedone===false">
      <div class="d-flex justify-content-center align-items-center">
        <h2 class="pt-5 black" style="display: block;">
          Share this report
        </h2>
         
      </div>
      <p class="d-flex justify-content-center align-items-center" style="display: block;">To share with multiple users, press enter after each email address captured or simply use a ';'.</p>
      <form [formGroup]="shareToForm">
        <div class="ls-onboarding p-5">
          <div class="row row-cols-1">
            <div class="col">
              <div class="form-group">
                <label for="message">Message</label>
                <textarea formControlName="message" class="form-control">
                 
                </textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8" style="border-bottom: 1px solid rgba(0,53,188,0.8); margin-left: 15px;">
              <div class="form-group" style="margin-bottom: 0;">
                <label for="mailTo">Email To</label>

                <div class="badge badge-light m-1" *ngFor="let eml of emailList">
                  {{ eml }}&nbsp;&nbsp;&nbsp;<a href="javascript:void(0);" class="text-dark"
                    (click)="remEmail(eml)">X</a>
                </div>

                <input formControlName="mailTo" type="text" class="form-control float-left noborder" #mailTo
                  aria-describedby="mailTo" style="width:90%; border: 0 !important;">
                <button class="btn btn-sm btn-primary float-left" (click)="addEmail()"
                  style="display: none;">+</button>
                <div style="clear: both;"></div>
              </div>
            </div>
            <div class="col-lg-3" style="padding-top: 26px;">
              <button type="button" class="form-control btn btn-primary" (click)="shareReport()"
                *ngIf="this.shareSending==false">Share Report</button>
            </div>
          </div>
          <div class="alert alert-danger text-center mt-2" *ngIf="this.statusmessage!=''">
            {{this.statusmessage}}
          </div>
        </div>
      </form>

      <div class="row" *ngIf="this.shareSending==true">
        <div class="bs-component" style="width: 200px; margin:auto;">
          <span class="ls-loader"> </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
